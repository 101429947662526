import mapVerify from '@/utils/formValidate'

const getFormatter = (data, value) => {
  return data?.find(t => t.value === value)?.label ?? value
}

export const getMoreQueryFormData = (that, formModel) => {
  const moreQueryData = [
    // 库位编码
    {
      name: 'factoryPositionCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.factoryPositionCode'),
      clearable: true
    },
    // 库位名称
    {
      name: 'factoryPositionName',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.factoryPositionName'),
      clearable: true
    },
    // 地图节点编码
    {
      name: 'mapNodeCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.mapNodeCode'),
      clearable: true
    },
    // 库位类型
    {
      name: 'factoryPositionType',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.factoryPositionType'),
      clearable: true,
      filterable: true,
      data: that.factoryPositionTypeList,
      mchange: that.handleFactoryPositionTypeChange
    },
    // 所属工厂
    {
      name: 'factoryId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingFactory'),
      clearable: true,
      filterable: true,
      data: that.factoryList,
      mchange: that.handleFactoryChange
    },
    // 所属车间
    {
      name: 'workshopId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingWorksop'),
      clearable: true,
      filterable: true,
      data: that.workshopList,
      mchange: that.handleWorkshopChange,
      disabled: !!formModel.warehouseId
    },
    // 所属仓库
    {
      name: 'warehouseId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingWarehouse'),
      clearable: true,
      filterable: true,
      data: that.warehouseList,
      // 车间、仓库互斥 生产库位 不可选 仓库
      disabled:
        !!formModel.workshopId ||
        formModel.factoryPositionType === 'factoryPositionTypeProduce',
      mchange: that.handleWarehouseChange
    },
    // 所属库区
    {
      name: 'warehouseAreaId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingWarehouseArea'),
      data: that.warehouseAreaList,
      disabled: formModel.factoryPositionType === 'factoryPositionTypeProduce'
    },
    // 所属区域
    {
      name: 'areaId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingArea'),
      clearable: true,
      filterable: true,
      data: that.areaList,
      disabled: formModel.factoryPositionType === 'factoryPositionTypeWarehouse'
    },
    // 库位状态
    {
      name: 'factoryPositionStatus',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.factoryPositionStatus'),
      data: that.freezeFlagList
    },
    // 启用状态
    {
      name: 'usableFlag',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.usableFlag'),
      data: that.statusFlagList
    }
  ]
  moreQueryData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that, options) => {
  const searchTableItem = [
    // 库位编码
    {
      prop: 'factoryPositionCode',
      label: that.$t('lang.gles.base.factoryPositionCode'),
      isShow: true,
      minWidth: 140,
      sortable: true
    },
    // 库位名称
    {
      prop: 'factoryPositionName',
      label: that.$t('lang.gles.base.factoryPositionName'),
      isShow: true,
      minWidth: 140
    },
    {
      prop: 'factoryPositionType',
      label: that.$t('lang.gles.base.factoryPositionType'),
      isShow: true,
      minWidth: 78,
      formatter(row, column) {
        return getFormatter(that.factoryPositionTypeList, row[column])
      }
    },
    {
      prop: 'mapNodeCode',
      label: that.$t('lang.gles.base.mapNodeCode'),
      isShow: true
    },
    {
      prop: 'factoryName',
      label: that.$t('lang.gles.base.belongingFactory'),
      isShow: true
    },
    {
      prop: 'workShopName',
      label: that.$t('lang.gles.base.belongingWorksop'),
      isShow: true
    },
    {
      prop: 'warehouseName',
      label: that.$t('lang.gles.base.belongingWarehouse'),
      isShow: true
    },
    {
      prop: 'warehouseAreaName',
      label: that.$t('lang.gles.base.belongingWarehouseArea'),
      isShow: true
    },
    {
      prop: 'areaName',
      label: that.$t('lang.gles.base.belongingArea'),
      isShow: true
    },
    // 入库顺序
    {
      prop: 'inStoreOrder',
      label: that.$t('lang.gles.base.inStoreOrder'),
      isShow: true,
      minWidth: 78
    },
    // 出库顺序
    {
      prop: 'outStoreOrder',
      label: that.$t('lang.gles.base.outStoreOrder'),
      isShow: true,
      minWidth: 78
    },
    // 物料上限
    {
      prop: 'materialMaximum',
      label: that.$t('lang.gles.base.materialMaximum'),
      isShow: true,
      minWidth: 78
    },
    // 容器上限
    {
      prop: 'containerMaximum',
      label: that.$t('lang.gles.base.containerMaximum'),
      isShow: true,
      minWidth: 78
    },
    // 库位状态
    {
      prop: 'factoryPositionStatus',
      label: `${that.$t('lang.gles.base.factoryPositionStatus')}`,
      isShow: true,
      minWidth: 78,
      formatter(row, column) {
        return getFormatter(that.freezeFlagList, row[column])
      }
    },
    // 启用状态
    {
      prop: 'usableFlag',
      label: `${that.$t('lang.gles.base.usableFlag')}`,
      isShow: true,
      slotName: 'statusSlot',
      showOverflowTooltip: false,
      minWidth: 78,
      formatter(row, column) {
        return getFormatter(that.statusFlagList, row[column])
      }
    },
    // 状态变更原因
    {
      prop: 'statusChangeReason',
      label: `${that.$t('lang.gles.base.statusChangeReason')}`,
      isShow: true,
      showOverflowTooltip: false,
      minWidth: 110
    },
    // 库位描述
    {
      prop: 'factoryPositionDescription',
      label: `${that.$t('lang.gles.base.goodsPositionDesc')}`,
      isShow: true
    }
  ]

  searchTableItem.forEach((item) => {
    item.showOverflowTooltip = true
  })
  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, row) => {
  const formData = [
    // 地图节点编码
    {
      name: 'mapNodeCode',
      value: row.mapNodeCode || '',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.mapNodeCode'),
      rules: mapVerify(['required']),
      appendSlotName: 'inputCodeTipIcon',
      class: 'tip-icon',
      show: true
    },
    // 库位编码
    {
      name: 'factoryPositionCode',
      value: row.factoryPositionCode || '',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.factoryPositionCode'),
      placeholder: '50个字符以内，支持字母、数字、-或上述组合',
      rules: mapVerify(['required', 'inputCode']),
      appendSlotName: 'inputCodeTipIcon',
      class: 'tip-icon',
      show: true
    },
    // 库位类型
    {
      name: 'factoryPositionType',
      value: row.factoryPositionType || '',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.factoryPositionType'),
      rules: mapVerify(['required']),
      appendSlotName: 'inputCodeTipIcon',
      filterable: true,
      data: that.factoryPositionTypeList,
      mchange: that.handleFactoryPositionTypeChange,
      show: true,
      class: 'tip-icon'
    },
    // 库位名称
    {
      name: 'factoryPositionName',
      value: row.factoryPositionName || '',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.factoryPositionName'),
      rules: mapVerify(['required', 'inputLen200']),
      placeholder: '200个字符以内，支持任意字符',
      clearable: true,
      show: true
    },
    // 所属工厂
    {
      name: 'factoryId',
      value: row.factoryId || '',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingFactory'),
      rules: mapVerify(['required']),
      clearable: true,
      filterable: true,
      data: that.factoryList,
      show: !!row.factoryPositionType,
      mchange: that.handleFactoryChange
    },
    // 所属车间
    {
      name: 'workshopId',
      value: row.workshopId || '',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      rules:
        row.factoryPositionType === 'factoryPositionTypeProduce'
          ? mapVerify(['required'])
          : [],
      label: that.$t('lang.gles.base.belongingWorksop'),
      appendSlotName:
        row.factoryPositionType === 'factoryPositionTypeWarehouse'
          ? 'inputCodeTipIcon'
          : '',
      data: that.workshopList,
      show: !!row.factoryPositionType,
      mchange: that.handleWorkshopChange,
      disabled:
        !!row.warehouseId &&
        row.factoryPositionType === 'factoryPositionTypeWarehouse',
      class: 'tip-icon'
    },
    // 所属仓库
    {
      name: 'warehouseId',
      value: row.warehouseId || '',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingWarehouse'),
      appendSlotName:
        row.factoryPositionType === 'factoryPositionTypeWarehouse'
          ? 'inputCodeTipIcon'
          : '',
      filterable: true,
      data: that.warehouseList,
      show: row.factoryPositionType === 'factoryPositionTypeWarehouse',
      mchange: that.handleWarehouseChange,
      disabled: !!row.workshopId,
      class: 'tip-icon'
    },
    // 所属库区
    {
      name: 'warehouseAreaId',
      value: row.warehouseAreaId || '',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingWarehouseArea'),
      rules: mapVerify(['required']),
      filterable: true,
      data: that.warehouseAreaList,
      show: row.factoryPositionType === 'factoryPositionTypeWarehouse'
    },
    // 所属区域
    {
      name: 'areaId',
      value: row.areaId || '',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingArea'),
      rules: mapVerify(['required']),
      filterable: true,
      data: that.areaList,
      show: row.factoryPositionType === 'factoryPositionTypeProduce'
    },
    // 入库顺序
    {
      name: 'inStoreOrder',
      value: row.inStoreOrder || '',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.inStoreOrder'),
      placeholder: '大于0的正整数',
      clearable: true,
      show: !!row.factoryPositionType,
      rules: mapVerify(['positiveIntegerLen5'])
    },
    // 出库顺序
    {
      name: 'outStoreOrder',
      value: row.outStoreOrder || '',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.outStoreOrder'),
      placeholder: '大于0的正整数',
      clearable: true,
      show: !!row.factoryPositionType,
      rules: mapVerify(['positiveIntegerLen5'])
    },
    // 物料上限
    {
      name: 'materialMaximum',
      value: row.materialMaximum || '',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.materialMaximum'),
      placeholder: '大于0的正整数',
      clearable: true,
      show: !!row.factoryPositionType,
      rules: mapVerify(['positiveIntegerLen5'])
    },
    // 容器上限
    {
      name: 'containerMaximum',
      value: row.containerMaximum || '',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.containerMaximum'),
      placeholder: '大于0的正整数',
      clearable: true,
      show: !!row.factoryPositionType,
      rules: mapVerify(['positiveIntegerLen6'])
    },
    // 库位描述
    {
      name: 'factoryPositionDescription',
      value: row.factoryPositionDescription || '',
      span: 12,
      component: 'elInput',
      type: 'textarea',
      rows: 6,
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.goodsPositionDesc'),
      clearable: true,
      show: !!row.factoryPositionType,
      rules: mapVerify(['inputLen500'])
    }
  ]
  return formData.filter((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
    return item.show
  })
}
