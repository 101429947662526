<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="codeOrName"
      :value.sync="baseFormModel.codeOrName"
      :placeholder="$t('lang.gles.common.pleaseInputCodeOrName')"
      :query-list="moreQueryData"
      @baseSearch="handleBaseSearch"
      @moreSearch="handleMoreSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <el-button type="primary" icon="el-icon-plus" @click="updateCom">
          {{ $t('lang.gles.common.addNew') }}
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="handleExportTable"
        >
          {{ $t('lang.gles.common.export') }}
        </el-button>
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @editItem="editItem"
        @delItem="delItem"
        @pageChange="pageChange"
      >
        <template #factoryPositionStatusSlot="{ row }">
          <span class="plan-task-status">
            <i
              class="ui-dot-process"
              :style="{
                '--dotColor': getFactoryPositionStatusColor(
                  row.factoryPositionStatus
                )
              }"
            />
            {{ row.factoryPositionStatusName }}
            {{ getDictLabel(freezeFlagList, row.factoryPositionStatus) }}
          </span>
        </template>
        <template #statusSlot="{ row }">
          <span class="plan-task-status">
            <i
              class="ui-dot-process"
              :style="{ '--dotColor': getUsableFlagColor(row.usableFlag) }"
            />
            {{ getDictLabel(statusFlagList, String(row.usableFlag)) }}
          </span>
        </template>
        <template #enableOptSlot="{ row }">
          <el-button
            v-loading="row.id === currentRow.id && loading"
            type="text"
            class="mr10 ml0 f12"
            :disabled="!isEnableOptEnabled({ row })"
            @click="handleChangeStatus({ row })"
          >
            {{
              row.usableFlag === 0
                ? $t('lang.gles.common.disable')
                : $t('lang.gles.common.enable')
            }}
          </el-button>
        </template>
      </m-table>
    </el-card>
  </div>
</template>
<script>
import axios from 'axios'
import ColumnConfig from '@/components/columnConfig'
import queryView from '@/components/moreQuery/queryView.vue'
import MTable from '@/libs_sz/components/MTable/MTable'
import { getMoreQueryFormData, getSearchTableItem } from '../data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import commonMixins from '@/mixins/commonMixins'
import { mapState } from 'vuex'
import { downloadFn } from '@/utils/utils'
export default {
  name: 'FactoryPositionList',
  components: {
    ColumnConfig,
    MTable,
    queryView
  },
  mixins: [listMixins, commonMixins],
  data() {
    return {
      baseFormModel: {
        codeOrName: ''
      },
      tableData: [],
      formModel: {},
      tableExtendConfig: {
        sortNum: true,
        checkBox: false,
        operateWidth: '140px',
        operate: [
          {
            event: 'enableItem',
            label: this.$t('lang.gles.common.enable'),
            slotName: 'enableOptSlot'
          },
          {
            event: 'editItem',
            label: this.$t('lang.gles.common.edit')
          },
          {
            event: 'delItem',
            confirm: true,
            confirmMessage: this.$t('lang.gles.common.deleteConfirmMsg0'),
            label: this.$t('lang.gles.common.delete'),
            customClass: 'danger'
          }
        ]
      },
      timer: null,
      loading: false,
      currentRow: {},
      cancelToken: null,
      timerCount: 0
    }
  },
  computed: {
    ...mapState('base', [
      'factoryPositionTypeList',
      'statusFlagList',
      'freezeFlagList'
    ]),
    moreQueryData() {
      return getMoreQueryFormData(this, { ...this.formModel })
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      const methods = {
        linefeed: this.linefeed
      }
      return getSearchTableItem(this, { ...this.options }, methods)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  async created() {
    this.doSearch(this.baseFormModel)
    this.initSelectList()
  },
  destoryed() {
    clearInterval(this.timer)
  },
  methods: {
    /**
     * 初始化下拉列表
     */
    initSelectList() {
      this.getFactoryList()
      this.getWorkshopList()
      this.getAreaList()
      this.getWarehouseList()
      this.getWarehouseAreaList()
      if (!this.factoryPositionTypeList?.length) {
        this.$store.dispatch('base/getFactoryPositionTypeList')
      }
      if (!this.statusFlagList?.length) {
        this.$store.dispatch('base/getStatusFlagList')
      }
    },
    /**
     * 库位类型 change
     */
    handleFactoryPositionTypeChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      this.resetFactory(this.formModel)
      this.resetWorkshop(this.formModel, 'all')
      this.resetWarehouse(this.formModel, 'all')
      this.resetArea(this.formModel, 'all')
      this.resetWarehouseArea(this.formModel, 'all')
    },
    /**
     * 所属工厂 change
     * 工厂-车间
     * 工厂-仓库
     */
    handleFactoryChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      this.resetWorkshop(this.formModel, 'cascade')
      this.resetWarehouse(this.formModel, 'cascade')
      const type = !val ? 'all' : ''
      this.resetArea(this.formModel, type)
      this.resetWarehouseArea(this.formModel, type)
    },
    /**
     * 所属车间 change
     * 车间-区域
     * 车间-库区
     */
    handleWorkshopChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      this.resetArea(this.formModel, 'cascade')
      this.resetWarehouseArea(this.formModel, 'cascade')
    },
    /**
     * 所属仓库 change
     * 仓库-库区
     */
    handleWarehouseChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      this.resetWarehouseArea(this.formModel, 'cascade')
    },
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },
    /**
     * 更多查询
     */
    handleMoreSearch(data) {
      this.formModel = { ...data }
      this.doSearch({ ...data }, 'getFactoryPositionList')
    },
    /**
     * 查询
     */
    async doSearch(params, api = 'getFactoryPositionListByCodeOrName') {
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.base(api, this.pageData),
          params
        )
        if (code) return
        this.$store.commit(types.IS_LOADING, false)
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount: recordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount }
        this.tableData = recordList || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    /**
     * 库位状态 class
     * @param {*} state
     */
    getFactoryPositionStatusColor(state) {
      return state === 0 ? '#00c07b' : '#409eff'
    },
    /**
     * 启用状态 class
     * @param {*} state
     * 启用 绿色
     * 禁用 红色
     * 异常 橙色
     */
    getUsableFlagColor(state) {
      return state === 0 ? '#00c07b' : state === 1 ? '#e72f38' : '#ea9f35'
    },
    /**
     * 新增
     */
    updateCom() {
      this.$emit('updateCom', {
        currentCom: 'factoryPositionAdd',
        mode: 'add'
      })
    },
    /**
     * 启用禁用按钮是否可用
     * 1、状态=启用 且 状态变更原因=空时，DMP可以停用，库位页面可以停用
     * 2、状态=停用 且 状态变更原因=空时，DMP可以启用，库位页面可以启用；
     * 3、状态=停用 且 状态变更原因≠空时，DMP可以启用，库位页面不可以启用（不显示启用按钮）；
     * 4、状态=异常 且 状态变更原因≠空时，DMP可以启用，库位页面不可以启用（不显示启用按钮）；
     * 停用/异常时，只变更基础数据中的状态，不改变库存中数据的状态，且可以查询出库存数据
     */
    isEnableOptEnabled({ row }) {
      return !row.statusChangeReason
    },
    /**
     * 启用/禁用状态切换
     * 1、更若禁用库位时库位正在使用中，二次确认弹窗提示【存在执行中任务，是否仍强制禁用】；若无则可直接禁用
     * 2、“您确定要启用/禁用此库位吗？”。选择“确定”关闭提示框，更新库位的启用/禁用状态；选择“取消”，不执行此操作，关闭提示框
     */
    handleChangeStatus({ row }) {
      // let confirmMsg = '您确定要启用/禁用此库位吗？'
      let confirmMsg = this.$t(
        'lang.gles.base.wantEnable/DisableThisFactoryPosition'
      )
      // 原状态是启用，禁用时，如果货位属于“占用”状态
      if (row.usableFlag === 0 && row.factoryPositionStatus === 1) {
        // confirmMsg = '存在执行中任务，是否仍强制禁用？'
        confirmMsg = this.$t('lang.gles.base.hasTaskStillDisable')
      }
      if (confirmMsg) {
        this.$confirm(confirmMsg, '', {
          confirmButtonText: this.$t('lang.gles.common.confirm'),
          cancelButtonText: this.$t('lang.gles.common.cancel'),
          type: 'warning'
        }).then(() => {
          this.updateUsableStatus(row)
        })
        return
      }
    },
    /**
     * 接口更新库位启用/禁用状态
     */
    async updateUsableStatus(row) {
      this.currentRow = row
      this.loading = true
      const { data, code } = await this.$httpService(
        this.$apiStore.base('updateFactoryPosition'),
        {
          id: row.id,
          usableFlag: row.usableFlag ? 0 : 1,
          isUpdateUsableFlag: true
        }
      )
      if (code !== 0) {
        this.loading = false
        this.cancelToken = null
        return
      }
      const { taskId } = data
      if (!taskId) {
        this.$message.success(this.$t('lang.gles.common.operateSuccessfully'))
        row.usableFlag = row.usableFlag ? 0 : 1
        this.loading = false
        this.cancelToken = null
        return
      }
      this.timer = setInterval(() => {
        this.timerCount++
        this.queryDmpCallbackRecord(row, taskId)
      }, 3000)
    },
    async queryDmpCallbackRecord(row, taskId) {
      if (this.cancelToken) return
      this.cancelToken = axios.CancelToken
      const { data, code } = await this.$httpService(
        this.$apiStore.base('queryDmpCallbackRecord'),
        {
          taskId
        }
      )
      this.cancelToken = null
      if (code) {
        return
      }
      if (Number(data.execResult) !== 10) {
        this.loading = false
        if (Number(data.execResult) === 0) {
          this.$message.success(this.$t('lang.gles.common.operateSuccessfully'))
          row.usableFlag = row.usableFlag ? 0 : 1
        } else {
          this.$message.error(this.$t('lang.gles.common.operateFailed'))
        }
        clearInterval(this.timer)
        return
      }
      if (this.timerCount === 5) {
        clearInterval(this.timer)
        this.$message.warning(this.$t('lang.gles.common.timeOutTryAgainLater'))
        this.loading = false
        this.cancelToken = null
        this.timerCount = 0
        return
      }
    },
    /**
     * 导出货位
     */
    handleExportTable() {
      const params = this.$refs?.myQueryView.getHistorySearchData()
      const url = this.$apiStore.base(
        'exportFactoryPosition',
        this.pageData
      )?.url
      downloadFn(url, params)
    },
    /**
     * 编辑
     */
    editItem({ row }) {
      this.$emit('updateCom', {
        currentCom: 'factoryPositionAdd',
        mode: 'edit',
        row
      })
    },
    /**
     * 删除
     */
    async delItem({ row }) {
      const { code } = await this.$httpService(
        this.$apiStore.base('deleteFactoryPosition'),
        { id: row.id }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      if (this.tableData?.length < 2) {
        this.pageData.currentPage = 1
      }
      this.doSearch(this.baseFormModel)
    }
  }
}
</script>
