<template>
  <div class="basic-data-add">
    <header-path :paths="headerPaths" />
    <el-card class="add-card">
      <m-form
        ref="myForm"
        :form-data="baseFormData"
        label-position="right"
        :label-width="120"
        :extend-config="extendFormConfig"
        :button="button"
        class="basic-data-add-form"
        @submit="onSubmit"
        @reset="onCancel"
      >
        <template #inputCodeTipIcon="{ item }">
          <el-tooltip
            popper-class="workshop_code_tip-icon"
            effect="dark"
            :content="getToolTipContent(item)"
            placement="right"
          >
            <i class="el-icon-tip el-icon-question" />
          </el-tooltip>
        </template>
      </m-form>
    </el-card>
  </div>
</template>
<script>
import HeaderPath from '@/components/headerPath/index.vue'
import MForm from '@/libs_sz/components/MForm/MForm'
import { getEditBaseFormData } from '../data'
import addMixins from '@/mixins/addMixins'
import commonMixins from '@/mixins/commonMixins'
import { mapState } from 'vuex'

export default {
  name: 'FactoryPositionAdd',
  components: {
    MForm,
    HeaderPath
  },
  mixins: [addMixins, commonMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formModel: {}
    }
  },
  computed: {
    ...mapState('base', ['factoryPositionTypeList', 'mapNodeList']),
    headerPaths() {
      return this.mode === 'add'
        ? [this.$t('lang.gles.base.factoryPositionAdd')]
        : [this.$t('lang.gles.base.factoryPositionEdit')]
    },
    baseFormData() {
      return getEditBaseFormData(this, { ...this.formModel })
    }
  },
  created() {
    this.formModel = { ...this.rowDetail }
    if (this.mode === 'add') {
      this.getSerialBizCode('factoryPositionSerialNum', 'factoryPositionCode')
    }
    this.initSelectList()
  },
  methods: {
    /**
     * 初始化下拉列表
     */
    initSelectList() {
      this.getFactoryList()
      if (!this.factoryPositionTypeList?.length) {
        this.$store.dispatch('base/getFactoryPositionTypeList')
      }
      if (this.mode === 'edit') {
        this.getWorkshopList({ factoryId: this.formModel.factoryId })
        this.getWarehouseList({ factoryId: this.formModel.factoryId })
        this.getAreaList({ workshopId: this.formModel.workshopId })
        if (this.formModel.workshopId) {
          this.getWarehouseAreaList({ workshopId: this.formModel.workshopId })
        }
        if (this.formModel.warehouseId) {
          this.getWarehouseAreaList({ warehouseId: this.formModel.warehouseId })
        }
      }
    },
    /**
     * 库位类型 change
     * 重置选项
     * @param {*} val
     * @param {*} formItem
     */
    handleFactoryPositionTypeChange(val, formItem) {
      this.$set(this.formModel, formItem.name, val)
      this.resetFactory(this.formModel)
      this.resetWorkshop(this.formModel)
      this.resetArea(this.formModel)
      this.resetWorkshop(this.formModel)
      this.resetWarehouseArea(this.formModel)
    },
    /**
     * 所属工厂 change
     * 工厂-车间
     */
    handleFactoryChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      this.resetWarehouse(this.formModel, 'cascade')
      this.resetWarehouseArea(this.formModel)
      this.resetWorkshop(this.formModel, 'cascade')
      this.resetArea(this.formModel)
    },
    /**
     * 所属车间 change
     * 车间-区域
     * 车间-库区
     */
    handleWorkshopChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      this.resetArea(this.formModel, 'cascade')
      this.resetWarehouseArea(this.formModel, 'cascade')
    },
    /**
     * 所属仓库 change
     * 仓库-库区
     */
    handleWarehouseChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      this.resetWarehouseArea(this.formModel, 'cascade')
    },
    /**
     * 表单项 ? 提示信息
     */
    getToolTipContent(item) {
      let contentStr = ''
      switch (item.name) {
        case 'factoryPositionCode':
          contentStr =
            '自动生成2位字母+6位数字的唯一编码；可输入50个字符以内的字母、数字、-或上述组合'
          break
        case 'warehouseId':
        case 'workshopId':
          contentStr =
            '【所属车间】和【所属仓库】二选其一；与生产车间相关时选择车间，与仓储相关时选择仓库'
          break
        case 'mapNodeCode':
          contentStr = '选择RMS系统的地图节点编码'
          break
        case 'factoryPositionType':
          contentStr =
            '请选择库位类型：库区内的库位请选择【仓储库位】，车间区域内的库位请选择【生产库位】'
          break
        default:
          break
      }
      return contentStr
    },
    /**
     * 保存
     */
    onSubmit() {
      // 先校验
      this.$refs.myForm.getValidateFormModel().then(async (model) => {
        const params = { ...this.formModel }
        // 仓储库位
        if (params.factoryPositionType === 'factoryPositionTypeWarehouse') {
          if (!params.workshopId && !params.warehouseId) {
            this.$message.error(
              this.$t('添加失败，所属车间和所属仓库不能同时为空')
            )
            return
          }
        }
        this.mode === 'add'
          ? await this.insert(params)
          : await this.update(params)
      })
    },
    /**
     * 新增
     */
    async insert(params) {
      const { code } = await this.$httpService(
        this.$apiStore.base('insertFactoryPosition'),
        params
      )
      if (code) return
      this.onCancel()
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
    },
    /**
     * 编辑
     */
    async update(params) {
      const { code } = await this.$httpService(
        this.$apiStore.base('updateFactoryPosition'),
        params
      )
      if (code) return
      this.onCancel()
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
    },
    /**
     * 取消 新增/编辑
     */
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'factoryPositionList'
      })
    }
  }
}
</script>
